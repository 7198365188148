const em = document.querySelector(".email-c");
const fn = document.querySelector(".first-name");
const button = document.querySelector(".vente-cta.access");



function buttonUpdate() {
  if (fn.value !== "" && em.value !== "") {

    button.removeAttribute('disabled'); //enable input

  } else {

    button.setAttribute('disabled', true); //disable input
  }
}

document.addEventListener("click", buttonUpdate);
document.addEventListener("keyup", buttonUpdate);